import React, { useEffect } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { useAsync } from "react-async";
import { useAuth, useFunctions } from "reactfire";
import Session from "./Session";
import Loader from "./Loader";
import PoweredBy from "./PoweredBy";

const userId = `${Math.floor(Math.random() * 1000000)}`;

function SessionContainer() {
  const { search } = useLocation();
  const { token } = useParams();
  const functions = useFunctions();
  const auth = useAuth();
  const session = useAsync({
    deferFn: async () =>
      (await functions.httpsCallable("getSessionByToken")({
        userId,
        token
      })).data
  });
  const authRequest = useAsync({
    deferFn: ([authToken]) => auth.signInWithCustomToken(authToken)
  });

  useEffect(() => {
    session.run();
  }, []); // eslint-disable-line

  const authToken = session.data?.authToken;
  useEffect(() => {
    if (!authToken) {
      return;
    }

    authRequest.run(authToken);
  }, [authToken]); // eslint-disable-line

  if (session.isRejected) {
    return (
      <div className="mw7 center pt4 ph2">
        <div className="flex justify-end mb3">
          <PoweredBy />
        </div>
        <div className="pa3 near-black bg-light-yellow br2">
          <span>Wrong PIN code.</span>
          <Link className="ml2 black fw5" to={`/${search}`}>
            Try again
          </Link>
        </div>
      </div>
    );
  }

  if (!authRequest.isSettled) {
    return (
      <div className="mw7 center pt4">
        <div className="flex justify-end ph2">
          <PoweredBy />
        </div>
        <Loader />
      </div>
    );
  }

  const workspaceId = session.data?.workspaceId;
  const sessionId = session.data?.sessionId;

  return (
    <Session workspaceId={workspaceId} sessionId={sessionId} userId={userId} />
  );
}

export default SessionContainer;
