import React from "react";
import VendorLoader from "react-loaders";

function Loader() {
  return (
    <div className="flex justify-center pv2">
      <VendorLoader active type="ball-pulse" color="#00000080" />
    </div>
  );
}

export default Loader;
