import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import { useQuery } from "../utils";

function App() {
  const { search } = useLocation();
  const history = useHistory();
  const [token, setToken] = useState("");
  const query = useQuery();
  const isEmbedded = query?.embed === "true";

  const onSubmit = e => {
    e.preventDefault();

    if (token.length !== 6) {
      return; // ToDo: validation
    }

    history.push(`/${token}${search}`);
  };

  return (
    <div className="mw7 center">
      {!isEmbedded && (
        <div className="pt2 pt5-ns ph2">
          <a
            className="f4 fw5 dim pointer black"
            href="https://prezcall.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            PrezCall
          </a>
        </div>
      )}
      <div className="pa4-l mt5 flex">
        <form
          onSubmit={onSubmit}
          className="center pa4 br2-ns ba b--dashed bg-near-white"
        >
          <fieldset className="cf bn ma0 pa0">
            <legend className="pa0 f5 f4-ns mb3 black-80">PIN code:</legend>
            <div className="cf">
              <ReactCodeInput fields={6} onChange={value => setToken(value)} />
              <input
                className="mt4 f6 f5-l button-reset fl pv3 tc bn bg-animate bg-black-70 hover-bg-black white pointer w-100 br2-ns"
                type="submit"
                value="OK"
              />
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  );
}

export default App;
