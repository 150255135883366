import React from "react";

const containerStyles = {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 99999
};

const pathStyle = {
  fill: "none",
  stroke: "#8cff32",
  strokeLinejoin: "round",
  strokeLinecap: "round"
};

function DrawArea({ annotations = [] }) {
  return (
    <div style={containerStyles}>
      <Drawing lines={annotations} />
    </div>
  );
}

function Drawing({ lines }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      {lines.map((line, index) => (
        <DrawingLine key={index} line={line} />
      ))}
    </svg>
  );
}

function DrawingLine({ line }) {
  const pathData =
    "M " +
    line
      .map(({ x, y }) => {
        return `${x} ${y}`;
      })
      .join(" L ");

  return <path style={pathStyle} d={pathData} />;
}

export default DrawArea;
