import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { FirebaseAppProvider } from "reactfire";
import { pdfjs } from "react-pdf/dist/entry.webpack";
import "./index.css";
import App from "./components/App";
import SessionContainer from "./components/SessionContainer";
import * as serviceWorker from "./serviceWorker";
import { firebaseConfig } from "./firebase";
import Loader from "./components/Loader";

import "@voxeet/react-components/dist/voxeet-react-components.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "loaders.css/src/animations/ball-pulse.scss";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

function Root() {
  return (
    <Switch>
      <Route exact path="/:token">
        <SessionContainer />
      </Route>
      <Route exact path="/">
        <App />
      </Route>
    </Switch>
  );
}

ReactDOM.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig}>
    <Router>
      <Suspense fallback={<Loader />}>
        <Root />
      </Suspense>
    </Router>
  </FirebaseAppProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
