import React from "react";
import { useFirestore, useFirestoreDocData } from "reactfire";
import { useParams } from "react-router-dom";
import Chat from "./Chat";
import VideoCall from "./VideoCall2";
import PDFViewer from "./PDFViewer";
import { useQuery } from "../utils";

function Session({ workspaceId, sessionId, userId }) {
  const { token } = useParams();
  const query = useQuery();
  const isEmbedded = query?.embed === "true";
  const firestore = useFirestore();
  const sessionRef = firestore.doc(
    `workspaces/${workspaceId}/sessions/${sessionId}`
  );
  const session = useFirestoreDocData(sessionRef);
  const fileDownloadUrl = session?.resource?.fileDownloadUrl;
  const annotations = JSON.parse(session?.resource?.annotations || "[]");
  const videoCall = session?.videoCall || false;
  const currentPageIndex = session?.resource?.currentPageIndex;

  const containerClassName = isEmbedded ? "h-100" : "pt5";

  return (
    <div className={`mw7 center ${containerClassName}`}>
      {videoCall && (
        <div className="relative">
          <VideoCall room={token} />
        </div>
      )}
      {!videoCall && fileDownloadUrl && (
        <div className="relative">
          <PDFViewer
            documentUrl={fileDownloadUrl}
            currentPageIndex={currentPageIndex}
            annotations={annotations}
          />
        </div>
      )}
      <Chat sessionId={sessionId} userId={userId} />
    </div>
  );
}

export default Session;
