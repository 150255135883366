import React from "react";
import {
  reducer as voxeetReducer,
  ConferenceRoom,
  VoxeetProvider
} from "@voxeet/react-components";
import thunkMidleware from "redux-thunk";
import { combineReducers, createStore, applyMiddleware } from "redux";
import { streamCredentials } from "../config";

const { consumerKey, consumerSecret } = streamCredentials;

const reducers = combineReducers({
  voxeet: voxeetReducer
});

const configureStore = () =>
  createStore(reducers, applyMiddleware(thunkMidleware));

function VideoCall2({ room = "test" }) {
  return (
    <div>
      <VoxeetProvider store={configureStore()}>
        <ConferenceRoom
          autoJoin
          consumerKey={consumerKey}
          consumerSecret={consumerSecret}
          conferenceAlias={room}
          isWidget={false}
          isWebinar
          displayActions={[]}
          actionsButtons={() => null}
        />
      </VoxeetProvider>
    </div>
  );
}

export default VideoCall2;
