import React, { useState } from "react";
import { Document, Page } from "react-pdf/dist/entry.webpack";
import useResizeObserver from "use-resize-observer/polyfilled";
import DrawArea from "./DrawArea";
import Loader from "./Loader";
import PoweredBy from "./PoweredBy";

function PDFViewer({ documentUrl, currentPageIndex, annotations = [] }) {
  const { ref, width } = useResizeObserver();
  const [loadingState, setLoadingState] = useState({
    state: "pending"
  });
  const totalPages = loadingState?.data?.numPages;

  return (
    <div>
      {loadingState.state === "pending" ? (
        <Loader />
      ) : (
        <div className="mb2 ph2 pt2 flex justify-between">
          <div>
            Slide: {currentPageIndex + 1} / {totalPages}
          </div>
          <PoweredBy />
        </div>
      )}
      <div ref={ref}>
        <Document
          file={documentUrl}
          onLoadSuccess={data =>
            setLoadingState({
              state: "resolved",
              data
            })
          }
          onLoadError={error =>
            setLoadingState({
              state: "rejected",
              error
            })
          }
          noData="session_no_file_url"
          loading={<Loader />}
          error="session_file_loading_error"
        >
          <Page width={width} pageIndex={currentPageIndex}>
            {annotations.length > 0 && <DrawArea annotations={annotations} />}
          </Page>
        </Document>
      </div>
    </div>
  );
}

export default PDFViewer;
