import React from "react";

function PoweredBy({ className, style }) {
  return (
    <div className={className} style={style}>
      powered by
      <a
        className="ml2 fw5 dim pointer black"
        href="https://prezcall.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        PrezCall
      </a>
    </div>
  );
}

export default PoweredBy;
