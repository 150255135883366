import React, { useState, useRef, useEffect } from "react";
import {
  Chat as VendorChat,
  Channel,
  Thread,
  Window,
  MessageList,
  MessageInput
} from "stream-chat-react";
import { StreamChat } from "stream-chat";

const streamApiKey = "87crp5aegjjm";

const Chat = ({ sessionId, userId }) => {
  const chatClient = useRef(new StreamChat(streamApiKey));
  const [channel, setChannel] = useState(null);
  const [chatVisible, setChatVisible] = useState(false);
  const client = chatClient.current;

  useEffect(() => {
    const handleChatSetup = async () => {
      await client.setGuestUser({ id: userId });
      setChannel(client.channel("messaging", sessionId));
    };
    handleChatSetup();
  }, [sessionId, userId]); // eslint-disable-line

  useEffect(() => {
    if (!client) {
      return;
    }

    const handler = () => {
      if (!chatVisible) {
        setChatVisible(true);
      }
    };

    client.on("message.new", handler);

    return () => client.off("message.new", handler);
  }, [client]); // eslint-disable-line

  if (!channel) {
    return <div>loading...</div>;
  }

  return (
    <div className="chat-wrapper">
      <div
        className="foo ba b--black-30 b--dashed"
        style={{ display: chatVisible ? "block" : "none" }}
      >
        <VendorChat client={client} theme="messaging light">
          <Channel channel={channel}>
            <Window>
              <MessageList />
              <MessageInput />
            </Window>
            <Thread />
          </Channel>
        </VendorChat>
      </div>
      <button
        className="f4 tc link dim br1 ph3 pv2 dib white bg-dark-gray pointer"
        style={{ width: 300 }}
        onClick={() => setChatVisible(!chatVisible)}
      >
        Chat
      </button>
    </div>
  );
};

export default Chat;
